import React from "react";
import { Row, Col, Typography } from "antd";
import Layout from "../../components/Layout/layout";
import SEO from "../../components/seo";
import CtaSecondary from "../../components/Buttons/secondary-cta";
import ogImage from "../../images/global_assets/og-image.png";
import LegalStyles from "../../components/Layout/LegalStyles";

const { Title } = Typography;

const ReserveTerms = () => {
  return (
    <Layout className="consumer-privacy">
      <SEO
        title="SpotOn | Privacy &amp; Legal"
        description="At SpotOn, we respect your privacy and are committed to being upfront and transparent. Please review our terms of services and privacy policies for more information."
        image={`https://spoton.com/${ogImage}`}
      />
      <div style={{ marginTop: 25 }}>
        <CtaSecondary target="/legal" ctaTitle="Legal" reverseIcon />
      </div>
      <main className="consumer-privacy__data">
        <Row>
          <Col lg={16} sm={24}>
            <div>
              <Title level={5}>EFFECTIVE AS OF October 19, 2022</Title>
              <Title level={1}>
              SPOTON TRANSACT LLC TERMS OF SERVICE FOR SPOTON TEAMWORK(™)
              </Title>
              <p>
              These SpotOn Transact LLC (“SpotOn”) Terms of Service (“Terms”), 
              outline the terms regarding Your use of SpotOn Teamwork(™) Services.
              </p>
              <p>
              These Terms are a legally binding contract between You and SpotOn.  
              If You do not agree with these Terms, do not register for a SpotOn 
              account, or purchase or use Services.
              </p>
              <p>
              By using or accessing the Services, or registering for a SpotOn account, 
              You agree to be bound by these Terms.
              </p>
              <p>
              If You use the Services on behalf of an entity, You agree to these 
              Terms for that entity and represent and warrant to SpotOn that You 
              have the authority to bind that entity to these Terms (in which event, 
              "You" and "Your" refer to that entity), unless that entity has a 
              separate paid contract in effect with SpotOn, in which event the 
              separate paid contract governs Your use of the Services.
              </p>
              <Title level={3}>1. DEFINITIONS</Title>
              <p>
              The following capitalized terms have the following meanings:
              </p>
              <p>
              “Affiliates” means, with respect to each party, entities that Control,
               are controlled by, or are under common Control with such party.
              </p>
              <p>
              “Aggregated Data” means statistics, benchmarks, measures, and other
               information or data that is: (a) anonymized by removing Personal
               Data or other information so the data cannot be attributable to a 
               specific SpotOn customer or user, or You (using commercially 
               reasonable efforts or as required by Applicable Laws), or (b) 
               combined with the other data, or (c) presented in a way which 
               does not reveal a specific SpotOn customer or user, or Your 
               identity (using commercially reasonable efforts or as required 
               by Applicable Laws).
              </p>
              <p>
              “Applicable Laws” means applicable national, federal, state, and 
              local laws, rules, guidelines, court or government agency orders, 
              and regulations in the United States and the country in Your 
              address on the Quote.
              </p>
              <p>
              “CCPA” means California Consumer Privacy Act of 2018, Cal. Civil 
              Code § 1798.100 et seq. and related regulations, as amended.
              </p>
              <p>
              “Confidential Information” means non-public information (a) 
              labeled or identified “confidential” (or the like), or (b) (either 
              in writing or verbal) of a type that a reasonable person should 
              understand to be confidential, including without limitation, Customer 
              Process Data, Customer Data, Personal Data, a third party’s information, 
              discounts, pricing, strategic roadmaps, product plans, product designs, 
              architecture, technology and technical information, security processes 
              and reports, audit reviews, business and marketing plans, business 
              processes, credit card/banking information, and information contained 
              in Your account. Confidential Information does not include information 
              a receiving party’s written records show was: (1) already known to 
              receiving party at the time of disclosure; (2) disclosed to the receiving 
              party by a third party who had the right to make such disclosure without 
              confidentiality restrictions; (3) or through no fault of the receiving 
              party has become, generally available to the public; or (4) independently 
              developed by receiving party without use of the disclosing party’s 
              Confidential Information.
              </p>
              <p>
              “Claim” means claim, demand, lawsuit, dispute, or proceeding.
              </p>
              <p>
              “Content” means files and meta-data associated with all files, 
              information, text, software, music, sounds, graphics, videos, 
              messages, tags, interactive features, photos, drawings, documents, 
              data, or other materials or media.
              </p>
              <p>
              “Control” means the beneficial ownership of more than fifty percent 
              (50%) of the voting power or equity in an entity.
              </p>
              <p>
              “Credentials” means access designations or authentication information 
              (e.g. multi-factor authentication or second access factors, user ID, 
              password, token, certificate, or Single Sign On) to access Services, 
              or a third party product or service.
              </p>
              <p>
              “Customer Data” means Your Confidential Information SpotOn maintains for 
              supporting You as a SpotOn customer, consisting only of (a) names, emails, 
              addresses, telephone numbers, photos or videos (i.e. recorded video conference 
              meetings where You consent to recordings), and other business contact 
              information of Your Representatives, (b) communications between You and 
              SpotOn relating to the performance of Services, and (c) communications 
              between You and SpotOn relating to the negotiation of Quotes, SOWs, and 
              other contracts governed by, incorporated into, and made part of the Terms.
              </p>
              <p>
              “Customer Process Data” means data that You provide to SpotOn for processing 
              in connection with Services, which may include Customer Data, and Personal Data.
              </p>
              <p>
              “Data Protection Claims” means any Claims arising from SpotOn’s breach of 
              Section 12 (Confidential Information) (except Customer Process Data that leverages 
              unencrypted endpoints), where such breach results in the unauthorized disclosure 
              of Confidential Information (except Customer Process Data that leverages 
              unencrypted endpoints).
              </p>
              <p>
              “Deliverables” means the product of all work performed under the Terms, including 
              without limitation notes, drawings, electronic files, reports, graphics, documentation, 
              computer programs (e.g. source code, object code, listings), inventions, creations, 
              equipment, devices, models, processes, ideas, methods, and work-in-progress.
              </p>
              <p>
              “Documentation” means SpotOn’s then-current operating manuals, user instructions, 
              technical literature, user guides, knowledge base, release notes, featured articles, 
              and functional materials describing the features, plans, options, and functions of 
              the Services made generally available by SpotOn to its customers or users.
              </p>
              <p>
              “SpotOn Tools” means the tools, libraries, know-how, ideas, concept, techniques, 
              and expertise SpotOn uses to develop the SpotOn Materials.
              </p>
              <p>
              “Fees” means fees quoted to You.
              </p>
              <p>
              “Feedback” means suggestions regarding features, functionality, modifications, 
              enhancements, improvements, or performance of SpotOn services or products.
              </p>
              <p>
              “Force Majeure Event” means cause beyond the reasonable control of an affected 
              party (including, without limitation, pandemic (e.g. COVID-19), war, wind, natural 
              disaster, lightning, fire, earthquake, flood, hurricane, riots, tsunami, Internet 
              service provider failures or delays, denial of Internet service attacks).
              </p>
              <p>
              “Identifiers” means recognizable or distinguishing names, servicemarks, logos, 
              identifiers, trademarks, symbols, words, phrases, designs, or a combination of 
              these items that identify the source of goods or services.
              </p>
              <p>
              “Incident” means a breach of security or confidentiality leading to accidental or 
              unlawful destruction or accidental loss, alteration, unauthorized disclosure or access 
              to Your Personal Data or Customer Process Data, in SpotOn’s possession, custody, or 
              control. “Incident” does not include unsuccessful attempts or activities that do not 
              compromise the security or confidentiality of Your Personal Data or Customer Process 
              Data, including, without limitation, unsuccessful log-in attempts, pings, port scans, 
              denial of service attacks, and other network attacks on firewalls or networked systems.
              </p>
              <p>
              “Insolvency Proceeding” means bankruptcy or insolvency proceeding.
              </p>
              <p>
              “Intellectual Property” means patents, copyrights, moral rights, trademarks, and trade 
              secrets, but not privacy or publicity rights.
              </p>
              <p>
              “Issue” means a single reproducible problem affecting the features and functionality 
              of the Services.
              </p>
              <p>
              “Losses” means damages, attorney fees, expert witness fees, and litigation costs.
              </p>
              <p>
              “Malware” means without limitation, viruses, worms, time bombs, Trojan horses, and 
              other malicious code, files, scripts, agents, or programs.  “Malware” does not include 
              programs used by SpotOn to terminate or suspend Your access to Services when You (a) 
              violate Section 7 (Acceptable Use), Section 24 (Specific Services Terms), or Services 
              use restrictions detailed in the Documentation or these Terms, or (b) exceed the 
              Subscription Period.
              </p>
              <p>
              “Outsourced Providers” means third parties to whom You or Your Affiliates outsource 
              Your business operations functions (i.e. accountants, bookkeepers).
              </p>
              <p>
              “Personal Data” means information relating to an identified or identifiable individual 
              who is a resident of the United States, including “Personal Information” as defined 
              under section 1798.140 of the CCPA.
              </p>
              <p>
              “Post” means post, upload, share, submit, email, provide, transmit, or publish.
              </p>
              <p>
              “Process” means any operation or set of operations performed upon Your Personal Data, 
              whether by automatic means, including collection, recording, organization, use, 
              transfer, disclosure, storage, manipulation, combination, and deletion of Your Personal Data.
              </p>
              <p>
              “Price List” means the official SpotOn list Fees for its Services.
              </p>
              <p>
              “Professional Services” means implementation, configuration, education, or operational or 
              technical consulting related to the Software.  “Professional Services” does not generally 
              include development of Software and/or Intellectual Property for the customer.
              </p>
              <p>
              “Proof of Entitlement” means a record (i.e. invoice, payment receipt, confirmation, license 
              certificate or key, or product) of the SKUs, types, quantities, and other use metrics of 
              Your purchases from SpotOn.
              </p>
              <p>
              “Quote” means an enrollment or ordering document.
              </p>
              <p>
              “Representatives” means a party’s employees or independent contractors.
              </p>
              <p>
              “Services” means collectively the SpotOn Teamwork Site, products, services (including 
              Professional Services, Subscription Services), Support, and other properties SpotOn owns 
              or operates.
              </p>
              <p>
              “Site” means SpotOn Teamwork websites (including <a href="https://www.dolcesoftware.com/" 
              target="_blank">www.dolcesoftware.com</a>).
              </p>
              <p>
              “Software” means SpotOn Teamwork-branded software, releases, tools and utilities.
              </p>
              <p>
              “SOW” means a statement of work or proposal issued by SpotOn that describes the 
              Professional Services.
              </p>
              <p>
              “Subscription Period” means the duration of Your Subscription Services specified in a Quote, 
              commencing on the start date, and continuing up to the renewal date or end date.
              </p>
              <p>
              “Subscription Services” means the SpotOn Teamwork-branded software-as-a-service platform, 
              including Software or downloadable related applications made available to You via the Internet 
              from computer equipment owned or operated by or for SpotOn.
              </p>
              <p>
              “Support” means telephone, email, text, chat, or web assistance in the resolution of an Issue 
              You report to SpotOn.
              </p>
              <p>
              “Taxes” means all transaction taxes, including foreign withholding taxes, and local, state, 
              provincial, federal or foreign taxes, levies, duties or similar governmental assessments of any 
              nature, including value-added taxes, excise, use, goods and services taxes, consumption taxes.
              </p>
              <p>
              “Third-Party Products” means software, code, applications, services, products, files, or data 
              from Third-Party Providers.
              </p>
              <p>
              “Third-Party Providers” means SpotOn licensors or suppliers.
              </p>
              <p>
              “You”, “Yourself”, and “Your” means a natural person or entity that is the customer, end user, 
              or user of Services.
              </p>
              <p>
              “Your Personal Data” means Personal Data that You provide or make available to SpotOn, or that 
              SpotOn otherwise Processes on Your behalf, in each case, in connection with the provision of or 
              as a part of the Services pursuant to these Terms at any time until the expiration or termination 
              of the Terms or the Services.
              </p>
              <Title level={3}>2. CHANGES TO THESE TERMS</Title>
              <p>
              SpotOn reserves the right to modify these Terms.  SpotOn will post the most current version of 
              these Terms at the Site.  Your continued use of Services after SpotOn publishes or notifies You 
              about SpotOn’s changes to the Terms means You consent to the updated Terms.
              </p>
              <p>
              Notwithstanding the foregoing, if You enter into a paid transaction, the Terms existing on the 
              effective date of Your Quote will govern until the Services expire or renew, after which the 
              then-current Terms apply to any renewals.
              </p>
              <Title level={3}>3. YOUR ACCOUNT</Title>
              <p>
              Certain aspects of the Services may require You to obtain an account by completing a registration 
              form and designating Credentials.  When registering with SpotOn You must: (a) provide true, 
              current, accurate, and complete information about Yourself on the registration form and (b) 
              maintain such information so it continues to be true, current, accurate, and complete.
              </p>
              <p>
              You are entirely responsible and liable for all information You Post via the Services, and all 
              activities occurring under Your account.
              </p>
              <p>
              You are responsible for maintaining the confidentiality of, and You agree not to share or transfer 
              account Credentials.  SpotOn will not be liable for Losses You incur relating to Your non-compliance 
              with this Section 3.  Only You may use Your SpotOn account.  If You become aware of unauthorized use 
              of the Services or Your account, or have questions about Your account, contact SpotOn Support at{' '}
              <a href="mailto:support@dolcesoftware.com">support@dolcesoftware.com</a>.
              </p>
              <p>
              If an entity (e.g. employer) provided You with Your account, this entity has rights to Your account 
              and may: (a) manage Your account (including suspending or canceling); (b) reset Your password; and 
              (c) view Your usage and data, including how and when Your account is used.
              </p>
              <Title level={3}>4. ELECTRONIC COMMUNICATIONS</Title>
              <p>
              <b>Electronic Notices; Emails.</b>   By registering an account with SpotOn or purchasing or signing up for 
              Services, You understand and agree that SpotOn may send (including via email) You information regarding 
              the Services, such as: (a) notices about Your use of the Services, including use violations; (b) updates 
              to the Services and new features, functionalities, services, or products; (c) promotional information 
              regarding SpotOn or third party products and services; and (d) Proof of Entitlements.  You may unsubscribe 
              from promotional information by following the instructions in the notices. If You don't consent to receive 
              notices (other than promotional information) electronically, stop using the Services.
              </p>
              <p>
              <b>Electronic Signatures.</b>  Each party agrees that the electronic signatures, whether digital or encrypted, of 
              the parties included in the Terms, Quotes, SOWs, and other contracts governed by, incorporated into, and 
              made part of these Terms, are intended to authenticate the writing and to have the same force and effect as 
              manual signatures. Delivery of the Terms, Quotes, SOWs, and other contracts, bearing an original manual or 
              electronic signature by facsimile transmission (including a facsimile delivered via the Internet), by 
              electronic mail in “portable document format” (“.pdf”) or similar format intended to preserve the original 
              graphic and pictorial appearance of a document, or through the use of electronic signature software 
              (e.g. DocuSign, AdobeSign, HelloSign) will have the same effect as physical delivery of the paper document 
              bearing an original signature.
              </p>
              <Title level={3}>5. SECURITY AND PRIVACY</Title>
              <p>
              <b>SpotOn Security.</b>  To provide You the Services under the Terms, and in the processing of Customer Data 
              and Customer Process Data, taking into account the cost of implementation and the nature, scope, context and 
              purposes of processing the Customer Data and Customer Process Data, SpotOn will secure Customer Data and Customer 
              Process Data according to best industry practices.  In the event of a security Incident defined by Applicable Laws 
              as reportable to authorities, SpotOn will notify You, or the owners of Personal Data You have custody of, in 
              accordance with Applicable Laws. For Customer Process Data, SpotOn will notify You, for You to notify any affected persons.
              </p>
              <p>
              <b>Your Security Responsibilities.</b>  You are responsible for:
              </p>
              <p>
                <ol type="a">
                    <li>
                        <p>
                        ensuring the use of secure endpoints; You are responsible for (1) ensuring encryption for the Customer Process Data 
                        flowing to or from the endpoint, to or from SpotOn service data center, and (2) selecting an appropriately encrypted 
                        endpoint.
                        </p>
                    </li>
                    <li>
                        <p>
                        managing the access to the SpotOn Tools used and ensuring appropriate role-based access.
                        </p>
                    </li>
                    <li>
                        <p>
                        notifying SpotOn at <a href="mailto:support@dolcesoftware.com">support@dolcesoftware.com</a> if You detect or suspect
                         a security Incident related to Services.
                        </p>
                    </li>
                    <li>
                        <p>
                        Ensuring that any Payment Card Data (as defined in PCI-DSS) is tokenized data only (no PAN or other PCI sensitive data 
                        as defined by PCI-DSS.
                        </p>
                    </li>
                </ol>
              </p>
              <p>
              <b>SpotOn Privacy Policy.</b>  You acknowledge Your use of the Services is subject to the <a href="/legal" 
              target="_blank">SpotOn terms and privacy policies</a> (“SpotOn Terms and Policies”) and SpotOn <a href="https://curryupnow.dolceclock.com/privacy_policy.html" 
              target="_blank">Teamwork Privacy Policy</a>, and You agree to the SpotOn Terms and Policies and the SpotOn 
              Teamwork Privacy Policy.
              </p>
              <p>
              <b>CCPA.</b>
              </p>
              <p>
              <u>CCPA Definitions.</u>  “Business Purpose”, “Commercial Purpose”, “Personal Information”, “Sell”, “Service Provider”, “Third Party” have 
              the meanings set forth in the CCPA.  “Consumer” means a natural person who purchases Services for personal use.  “Consumer Rights 
              Request” means a request from an individual relating to that individual’s Personal Information that is Your Personal Data.
              </p>
              <p>
              <u>Processing of Personal Information.</u>  SpotOn is not a Third Party as defined in the CCPA.  SpotOn is a Service Provider as defined in the CCPA.  
              SpotOn shall only Process Your Personal Data for the Business Purpose of providing the Services. Unless otherwise permitted under the 
              Terms or required by the CCPA, SpotOn shall not retain, use, or disclose Your Personal Data for a Commercial Purpose, or any purpose 
              other than to perform the Business Purposes contemplated by the Terms.  SpotOn shall not retain, use, or disclose Personal Information 
              outside of the direct business relationship between a Consumer and You.  SpotOn shall not Sell Your Personal Data.  Your provision of 
              access to Personal Information for Processing is not part of and explicitly excluded from the exchange of consideration, or any other 
              thing of value, between the parties.
              </p>
              <p>
              <u>CCPA Consumer Rights Request.</u>  If SpotOn receives a Consumer Rights Request for Customer Data or Customer Process Data, to the extent 
              legally permissible, SpotOn will advise the individual to submit the Consumer Rights Request to You. You will be responsible for responding 
              to such Consumer Rights Request. SpotOn shall reasonably cooperate with Your written requests to enable You to comply with a Consumer Rights Request.
              </p>
              <p>
              <u>Aggregated Data.</u>  SpotOn will be free (during and after the expiration or termination of the Terms or the Services), without obligation to You, 
              to collect, develop, create, extract, compile, synthesize, analyze, use, and/or commercialize, or share with third parties, Aggregated Data for 
              any purpose.
              </p>
              <p>
              <b>Credentials.</b>  In providing You Support or Professional Services, or allowing You to use features designed to interoperate with Services, 
              SpotOn may need access to Your Credentials.  If You agree to reveal to SpotOn Your Credentials, You shall provide Credentials with sufficient 
              permissions via a secure password management system for SpotOn Representatives to install and/or configure Subscription Services, perform 
              Support obligations, or allow access to accounts on the Subscription Services.  Upon providing Your Credentials to SpotOn, You grant SpotOn 
              permission to access Your accounts to perform the actions mutually agreed. You acknowledge and agree there are risks in giving SpotOn access 
              to Credentials, and release SpotOn from liability for damage, loss, or destruction to Your hardware, software, files, data (including Customer 
              Process Data and Customer Data), or environments (technical, network, systems, servers, or computer), which may occur during, or as a result of, 
              having access to Credentials.  YOU EXPRESSLY WAIVE ALL CLAIMS FOR LIABILITY, DESTRUCTION, LOSSES, INCLUDING WITHOUT LIMITATION ANY INDIRECT, 
              INCIDENTAL, SPECIAL, PUNITIVE, COVER, LOSS OF PROFITS OR REVENUE, OR CONSEQUENTIAL DAMAGES WHICH MAY OCCUR AS A RESULT OF SPOTON ACCESSING 
              CREDENTIALS.  You knowingly and voluntarily waive any and all rights and benefits conferred by California, United States Civil Code Section 
              1542, which reads: "A general release does not extend to claims that the creditor or releasing party does not know or suspect to exist in his 
              or her favor at the time of executing the release and that, if known by him or her, would have materially affected his or her settlement with 
              the debtor or released party."
              </p>
              <Title level={3}>6. LICENSE</Title>
              <p>
              <b>Subscription Services.</b>  Subject to these Terms, SpotOn grants You a worldwide, non-exclusive, non-transferable, non-assignable (except as set 
              forth in Section 23 (Miscellaneous)), and non-sublicensable license to access and use the Subscription Services identified in a Quote solely 
              to support the internal business operations of You and Your Affiliates for the Subscription Period according to these Terms and the Documentation.  
              SpotOn reserves all rights not specifically granted.
              </p>
              <p>
              <b>Evaluation.</b>  If You use the Subscription Services for evaluation, internal testing, free trial, or proof of concept, without paying SpotOn 
              Fees, SpotOn grants You a revocable, worldwide, non-exclusive, non-transferable, non-sublicensable, and non-assignable (except as set forth 
              in Section 23 (Miscellaneous)) license to access and use the Subscription Services identified solely for Your own non-production or production 
              (if specified in the Quote), internal evaluation to test the Subscription Services according to the Documentation (“Evaluation Right”).  
              Each Evaluation Right shall be for the Subscription Period mutually agreed by the parties at the commencement of the Evaluation Right, but if 
              the parties do not specify a Subscription Period in a Quote, the Subscription Period shall not exceed six (6) months, subject to SpotOn’s right 
              to terminate the Evaluation Right in its sole discretion at any time.  The Evaluation Right will be at no Fees, unless You exceed the Subscription 
              Period or scope of the Evaluation Right, or do not comply with these Terms or the Documentation.  Your Evaluation Right may be limited in functionality 
              and features, and SpotOn may change the limits at its sole discretion without notice.  You acknowledge and agree that (a) SpotOn provides Evaluation 
              Rights “AS IS” with no warranties or Support, (b) Section 19 (Indemnification) on SpotOn Indemnification does not apply to Evaluation Rights, and 
              (c) TO THE EXTENT NOT PROHIBITED BY LAW, IN NO EVENT WILL SPOTON’S AND ITS AFFILIATES’ TOTAL AND CUMULATIVE LIABILITY, FOR ALL CLAIMS OF ANY NATURE 
              ARISING OUT OF OR RELATED TO THE EVALUATION RIGHT EXCEED ONE HUNDRED UNITED STATES DOLLARS ($100).
              </p>
              <Title level={3}>7. ACCEPTABLE USE</Title>
              <p>
              You must not:  (a) conduct activity designed to overload, harm, impede the normal functioning, damage, disable, overburden, or impair the Services 
              (or network connected to the Services); (b) make generally available to third parties as a billable service, resell, redistribute, package, repackage, 
              sell, rent, sub-rent, lease, sub-license, sublease, encumber, or otherwise transfer the Services or any part of it; (c) use unauthorized means to modify, 
              reroute, or gain access to the Services (e.g. hacking, password mining) or attempt to carry out these activities; (d) use a malicious automated process 
              or service (such as Malware bots, scripts, data gathering or extraction methods, spiders, or periodic cachings of information stored by SpotOn) to access 
              or use the Services, or accounts, computer systems, or networks connected to the Services; (e) violate the allocations and amounts, and the features and 
              functionality provided in a Service; (f) use the Services to violate Applicable Laws or distribute Malware (You specifically agree not to upload files or 
              Customer Process Data to Subscription Service for SpotOn to Process that are infected with Malware); (g) disclose, conduct, perform, publicly display, 
              publish, or republish the results of benchmark, performance, comparison, or competitive tests or analysis involving the Services for any reason or purpose 
              without SpotOn prior written approval; (h) reverse-engineer (including reverse compiling to ensure interoperability), decompile, disassemble, modify, 
              translate, or make any attempt to discover or gain access to the source code, source files, underlying algorithms of SpotOn Intellectual Property, or 
              structure of all or any portion of Services, or create derivative works from Services; (i) violate SpotOn’s copyright fair use policy (Section 11 (Copyright 
              Complaints and Removal Policy); (j) impersonate a natural person, including a SpotOn Representative, or misrepresent Your affiliation with a natural person 
              or entity; (k) use meta tags or “hidden text” with SpotOn’s or Third-Party Providers’ Identifiers; (l) remove SpotOn proprietary or copyright notices from 
              the Services; (m) reproduce the Subscription Services, or any component thereof; (n) build a similar or competitive product or service; (o) use Services in 
              any manner not authorized by the Terms; (p) distribute, Post, or use Content You don’t have the right to (i.e. securities violations, under contractual or 
              fiduciary relationships (e.g. non-disclosure agreement breach) or is illegal (i.e. violates or infringes the Intellectual Property rights or the privacy or 
              publicity rights of any natural person or entity (e.g. attempt to obtain unauthorized access to Services, or SpotOn’s or Third-Party Providers’ accounts, 
              systems, networks, servers, computers, or databases)); harm minors (e.g. child pornography); mine information to obtain Personal Data; discredit or disparage 
              SpotOn, SpotOn Affiliates, Third-Party Providers, or a third party)); (q) forge TCP/IP packet or email headers or manipulate SpotOn’s or a third party’s 
              Identifiers or message or newsgroup posting in order to disguise the origin of Content; or (r) Post Content that contains misrepresentations to the Site.
              </p>
              <p>
              To the extent required by Applicable Laws, SpotOn will make available to You information reasonably required to ensure Services interoperability (such 
              information considered Confidential Information and subject to Section 12 (Confidential Information)) with Your independently managed products or services, 
              upon Your written request identifying relevant details of the products or services which interoperability is sought and the information needed, provided that 
              You first pay SpotOn any outstanding Fees.
              </p>
              <p>
              SpotOn retains the right to block, delete, or otherwise prevent delivery of any file, email, or other communication to or from the Services.
              </p>
              <p>
              You agree to comply with Services use restrictions detailed in the Documentation for the specific Services.
              </p>
              <p>
              <b>Permitted Third Party Usage.</b>  You may permit Your Affiliates, and Outsourced Providers to use the Services provided that: (a) the Affiliates and Outsourced 
              Providers shall only use and/or operate the Services, in accordance with the rights granted herein and Services use restrictions (including Section 7 
              (Acceptable Use) and Section 24 (Specific Services Terms)), (b) the actual usage of Services by You, Your Affiliates, and Outsourced Providers, in aggregate 
              shall not exceed the Proof of Entitlement You purchased, (c) You shall ensure that Your Affiliates and Outsourced Providers are aware of and comply with these 
              Terms and the Documentation; and (d) You shall be responsible for the acts and omissions of Your Affiliates’, and Outsourced Providers’ use of the Services.
              </p>
              <p>
              <b>Content.</b>
              </p>
              <p>
              <u>Ownership.</u>  You agree that You are either the original owner of Content You Post to the Services, or You have the necessary rights and 
              permissions to authorize SpotOn to use or Process Your Content. You agree to provide SpotOn evidence of such rights and permissions if SpotOn requests.
              </p>
              <p>
              <u>Back-up.</u>  You are responsible to back-up Your Content.  SpotOn is not a Content-archiving service and does not sell Content storage services.  
              If Applicable Laws prohibit exclusion of liability for lost Content, SpotOn will only be liable for cost of commercially reasonable and customary efforts 
              to recover the lost Content from Your last available back-up.
              </p>
              <p>
              <b>License from You for SpotOn to Host and/or Process Customer Process Data and Customer Data. You own all right, title, and interest to Customer 
                Process Data.</b> You are solely responsible for the accuracy, completeness, appropriateness, quality, and legality of all Customer Process Data 
                and Customer Data and for obtaining all rights related to Customer Process Data and Customer Data required by SpotOn to perform the Services.  
                You hereby grant SpotOn, SpotOn Affiliates, and their Representatives a worldwide,  fully paid, royalty free, transferable, sub-licensable, 
                non-exclusive license and right to use, reproduce, host, copy, transmit, process, distribute, modify, translate, and create derivative works 
                of Customer Process Data and Customer Data, and run, perform, or display Customer Process Data and Customer Data, and any program code created 
                by or for You using the Services (a) as necessary for SpotOn to provide the Services, perform Terms obligations, and exercise SpotOn’s rights, 
                titles, and interests under the Terms; (b) as necessary to provide Support, address Services issues and requests, or enhance, maintain, or improve 
                the Services; (c) as required by Applicable Laws; (d) as requested by You; or (e) to investigate or address security Incidents.
              </p>
              <Title level={3}>8.  SUSPENSION AND TERMINATION OF SERVICES</Title>
              <p>
              SpotOn may suspend or terminate the Services at any time, in its sole discretion, if SpotOn reasonably believes in good faith You are in violation 
              of the Terms or Applicable Laws, and Your material breach cannot be cured within 30 days.
              </p>
              <Title level={3}>9.  SPOTON PROPRIETARY RIGHTS</Title>
              <p>
              All contents of the Site and Services, including but not limited to logos, designs, text, software, technical drawings, configurations, graphics, 
              files, icons, images, audio clips, and their compilation (meaning the selection, collection, assembly, arrangement) and SpotOn Confidential 
              Information belong to SpotOn, and/or its Third-Party Providers or SpotOn Affiliates.
              </p>
              <p>
              SpotOn or its Third-Party Providers or SpotOn Affiliates own and reserve all right, title, and interest in and to the Services and all hardware, 
              software, and other items used to provide the Services, other than the rights expressly granted to You to use the Services and SpotOn Confidential 
              Information.  No title to, or ownership of, Intellectual Property or proprietary rights related to the Services or SpotOn Confidential Information 
              is transferred to You pursuant to these Terms.
              </p>
              <p>
              You shall not copy the Site, or any part of the Site.
              </p>
              <p>
              <b>Feedback.</b>  In the event You make Feedback that SpotOn adopts for its products or services, such Feedback shall be deemed automatically 
              assigned under these Terms to SpotOn, and become the sole and exclusive property of SpotOn.  Prior to submitting Feedback to SpotOn, You 
              agree to obfuscate Your Personal Data and Confidential Information.
              </p>
              <p>
              <b>SpotOn Identifiers.</b>  “SpotOn Transact” and the SpotOn Transact logo are registered trademarks of SpotOn Transact LLC in the United 
              States and other countries. SpotOn Teamwork, the SpotOn Teamwork logo are unregistered trademarks of SpotOn Transact LLC in the United States 
              and other countries.  All other trademarks, service marks, registered trademarks, or registered service marks are the property of their respective owners.
              </p>
              <p>
              This Section 9 (SpotOn Proprietary Rights) survives expiration or termination of the Terms or the Services.
              </p>
              <p>
              <Title level={3}>10.  THIRD-PARTY PRODUCTS, CONTENT, WEBSITES</Title>
              </p>
              <p>
              <b>Third-Party Products.</b>  The Services may include Third-Party Products. SpotOn grants You the specific rights the Third-Party Providers provide 
              SpotOn in the Third-Party Products.
              </p>
              <p>
              <b>Third Party Content or Websites.</b>  The Site provides links to third party websites or third party Content for Your convenience.  SpotOn makes 
              no representations or endorsements, and is not responsible for the reliability of statements made on third party websites or third party Content.  
              You access such third party websites or Content at Your own risk.
              </p>
              <Title level={3}>11. COPYRIGHT COMPLAINTS AND REMOVAL POLICY</Title>
              <p>
              SpotOn respects the Intellectual Property of others and will respond to alleged copyright infringement notices that comply with Applicable Laws. 
              </p>
              <p>
              SpotOn reserves the right to close Your accounts or remove information alleged to violate copyright laws or these Terms.
              </p>
              <p>
              Report alleged copyright violations to:  Attn: Copyright Agent, SpotOn Transact LLC, 100 California Street, 9th Floor, San Francisco, CA  94122, 
              Email:  Legal@spoton.com
              </p>
              <Title level={3}>12.  CONFIDENTIAL INFORMATION</Title>
              <p>
              <b>Customer Data and Customer Process Data Disclosures.</b>  You agree to disclose to SpotOn and its Third Party Providers only Personal Data, 
              Customer Data, and Customer Process Data in which You have obtained consent from data subjects (identified or identifiable natural persons), 
              or otherwise have the right to disclose to SpotOn for Processing.
              </p>
              <p>
              <b>Protection.</b>  Both parties agree to: (a) treat each party’s Confidential Information with the same degree of care a party treats its 
              own Confidential Information, but not less than reasonable care; (b) use each party’s Confidential Information only in connection with these 
              Terms and the Services; and (c) only share Confidential Information with Representatives and Affiliates who have a need to know to carry out 
              these Terms or as needed for the Services, and signed a non-disclosure agreement to treat Confidential Information as confidential or have 
              confidentiality obligations (e.g. professional responsibility rules) no less restrictive than this Section 12 (Confidential Information).
              </p>
              <p>
              <b>Compelled Disclosures.</b>  If the receiving party is requested or compelled by Applicable Laws to disclose the disclosing party's 
              Confidential Information ("Compelled Disclosure"), the receiving party’s disclosure of such Confidential Information shall not constitute 
              a breach of these Terms provided that the receiving party gives the disclosing party prompt written notice, unless notice is prohibited 
              by Applicable Laws, so that the disclosing party may attempt to seek an appropriate remedy. The receiving party shall (a) disclose only 
              that portion of the Confidential Information necessary to comply with Applicable Laws, (b) assert the privileged and confidential nature 
              of Confidential Information against the third party seeking disclosure; (c) reasonably cooperate with disclosing party to protect against 
              disclosure and/or obtain a protective order narrowing the scope of the Compelled Disclosure at disclosing party’s expense; and (d) continue 
              to treat Compelled Disclosures as confidential in other respects.
              </p>
              <p>
              <b>Confidential Information Return.</b>  Confidential Information always remains the property of its owner.  Upon termination or expiration 
              of the Terms, or upon written request of the disclosing party, the receiving party shall promptly return to the disclosing party or destroy, 
              to the extent commercially and technically feasible, all tangible materials (e.g. notes) and copies thereof, containing the Confidential 
              Information, except the receiving party may retain copies of disclosing party’s Confidential Information (a) stored electronically on data 
              archives or back-up systems or (b) to comply with Applicable Laws applicable to the receiving party, provided that such copies shall be 
              subject to the terms of these Terms while in receiving party’s possession.
              </p>
              <p>
              This Section 12 (Confidential Information) survives expiration or termination of the Terms or the Services.
              </p>
              <Title level={3}>13.  SUPPORT AND SERVICES UPDATES</Title>
              <p>
              SpotOn may end of life Services and related Support, add or remove functionalities or features, or suspend or stop the Services and 
              Support altogether, (a) without notice for Services and Support SpotOn provides for no Fees, (b) with notice according to the life cycle 
              for the specific version or release of the Services and Support SpotOn provides for a Fee, subject to SpotOn continuing to provide Services 
              and Support according to a Quote until the Subscription Period end date.
              </p>
              <Title level={3}>14.  PROFESSIONAL SERVICES</Title>
              <p>
              <b>SOW.</b>  You may receive Professional Services, as further described in a mutually agreed SOW or Quote. Each SOW or Quote will 
              include: (a) a description of the services, (b) a Fee for the services. The Fee will be as follows: (1) for Time and Materials, an 
              hourly rate and the estimated number of hours; (2) for Fixed Price, a total Fee.  Expense reimbursements, when applicable, will be 
              in addition to the Fee.  SpotOn will perform Professional Services with Representatives under SpotOn’s sole direction.
              </p>
              <p>
              <b>Resources.</b>  Resources (i.e. facilities, Representatives, hardware, software) for Professional Services shall be mutually agreed 
              no later than ten (10) business days prior to the Professional Services start date.  You shall provide resources as reasonably necessary 
              for SpotOn to timely complete the Professional Services.
              </p>
              <p>
              <b>Changes or Delays.</b>  If You request changes to a SOW or cause delays in the completion, additional Fees may apply, including Fees 
              for additional hours to transfer information, or re-plan or re-start the Professional Services. If You cause delays in the completion by 
              more than ten (10) business days, SpotOn may terminate the SOW or Quote for cause under Section 17 (Duration; Termination).
              </p>
              <p>
              <b>SpotOn Materials.</b>  SpotOn shall own all rights, title and interest in and to the Documentation, templates, training materials, 
              recordings, notes, drawings, designs, inventions, systems, processes, development, discovery, work of authorship, equipment, methods, 
              and other items (collectively the “SpotOn Materials”), including enhancements, improvements, and derivatives, SpotOn may provide You 
              as part of the Professional Services (including Intellectual Property therein, but excluding Your Confidential Information and Your 
              Identifiers that may be included in the SpotOn Materials, collectively, “Your Property”). SpotOn shall have the right to use 
              Your Property solely to provide Professional Services to You.  Until the expiration or termination of these Terms or the Services, 
              after Your full payment of the SOW for the Professional Services, SpotOn will provide You a worldwide, royalty free, limited, non-exclusive, 
              non-sublicensable, non-transferable, and terminable license to use SpotOn Materials solely for Your internal operations in connection with 
              Your authorized use of the Services. 
              </p>
              <p>
              <b>SpotOn Tools.</b>  SpotOn shall own any Intellectual Property in the SpotOn Tools.  Nothing herein shall be construed to assign or 
              transfer Intellectual Property in the SpotOn Tools.  To the extent SpotOn Tools are delivered with or as part of the SpotOn Materials, 
              they are licensed, not assigned, to You, on the same terms as the SpotOn Materials.
              </p>
              <p>
              <b>Customer Intellectual Property.</b>  Customer acknowledges and agrees Professional Services will not result in Customer Intellectual 
              Property or Deliverables owned by Customer.  In general, Professional Services do not involve custom Deliverables.
              </p>
              <Title level={3}>15. FEES; TAXES</Title>
              <p>
              <b>Fees.</b>  SpotOn offers no cost and paid Services.  If You choose a paid Service, You agree to pay the Fees according to the Quote or SOW.  
              SpotOn reserves the right to change its Fees and discounts, but such changes will not apply to completed purchases, or unexpired Quotes or SOWs 
              pending Your acceptance.  After a Subscription Period ends, Your use of the Services will be charged at the then-current Price List.
              </p>
              <p>
              <b>Discounts.</b>  Any Fees discounts You receive in a Quote or SOW are one-time only, and do not apply to renewal terms.  Upon the expiration 
              of each Subscription Period, annual Subscription Services Fees and Support Fees are subject to increase.
              </p>
              <p>
              <b>Taxes.</b>  All Fees for Services do not include Taxes. SpotOn may calculate Taxes payable by You based on the billing information 
              You provide at the time of purchase. All Fees are payable in full and without reduction for Taxes.  You shall not withhold from Fees 
              the Taxes imposed upon You by a taxing authority.  You are responsible for paying all Taxes associated with Fees, excluding SpotOn 
              income and payroll taxes.  If You are legally entitled to an exemption from the payment of Taxes, You will provide SpotOn with legally 
              sufficient tax exemption certificates for each taxing jurisdiction for which You claim exemption.  Unless prohibited by law, SpotOn 
              will apply the benefits of a requested tax exemption to charges after the date SpotOn receives and reasonably processes the tax exemption 
              certificates.
              </p>
              <p>
              <b>Currency.</b>  You will pay the Fees in the currency in the Quote.  You are responsible for all charges related to using the purchased 
              Services (including data charges and currency exchange settlements).
              </p>
              <p>
              <b>Non-refundable and No Cancellation.</b>  Except as specifically set forth in these Terms, all payments are non-cancelable, and all 
              payments made (including shipping, handling, and Taxes) are non-refundable, to the extent not prohibited by Applicable Laws, or except 
              in jurisdictions where an item is refundable.
              </p>
              <p>
              This Section 15 (Fees; Taxes) survives expiration or termination of the Terms or the Services.
              </p>
              <Title level={3}>16. BILLING/PAYMENT</Title>
              <p>
              If You select a paid Service, You must provide SpotOn current, complete, accurate, and authorized payment method information.  
              You authorize SpotOn or its third-party payment processing service provider to charge Your payment method for the Services You select.
              </p>
              <p>
              After You sign a Quote and create a SpotOn account, SpotOn will provide You a Proof of Entitlement.
              </p>
              <p>
              You shall pay Fees according to the payment terms in a Quote. Failure to pay expenses, Taxes, or Fees may result in SpotOn suspending 
              or terminating Services.
              </p>
              <p>
              At SpotOn’s discretion, past due amounts may accrue a late Fee equal to the lesser of (a) 1.5% per month, or (b) the maximum Applicable 
              Laws allow.
              </p>
              <p>
              This Section 16 (Billing/Payment) survives expiration or termination of the Terms or the Services.
              </p>
              <Title level={3}>17. DURATION; TERMINATION</Title>
              <p>
              <b>Subscription Services Renewals.</b> The Quotes indicate the start (if not indicated, the last signature date on the Quote) and 
              end dates for each Subscription Period. A Subscription Period may also begin on the date You first log in to the Services if You 
              purchase Services without signing a Quote.  The Subscription Services and these Terms automatically renew for additional terms 
              at the then-current SpotOn Price List for the same duration as the initial Subscription Period, at the end of each Subscription 
              Period, unless either party notifies the other party in writing of its intent not to renew the Subscription Services at least 
              thirty (30) days’ prior to the end of the Subscription Period.
              </p>
              <p>
              <b>Termination for Cause.</b>  Either party may terminate these Terms or the Services for cause with written notice to the other 
              party of a material breach of the Terms (a) upon thirty (30) days’ written notice if such breach remains uncured after the expiration 
              of such period, or (b) immediately if such breach cannot be cured. Outstanding Fees shall be paid by each party to the other party 
              within 30 days of the effective termination date.
              </p>
              <p>
              <u>Your Material Breach.</u>  If SpotOn terminates the Terms or the Services because of Your material breach, You:
              </p>
              <p>
              (a) agree to pay Fees for Professional Services rendered up to the effective termination date, accrued expenses You approved, and a 
              Fee determined at SpotOn’s sole discretion for resources allocated to SOWs not yet expired or completed.
              </p>
              <p>
              (b) remain liable for all Subscription Services and Support Fees until the Subscription Period end dates.
              </p>
              <p>
              <u>SpotOn Material Breach.</u>  If You terminate the Terms or the Services because of SpotOn material breach, SpotOn will refund You prepaid 
              Fees for Services not rendered after the effective termination date.
              </p>
              <p>
              <b>Termination for Insolvency.</b>   Either party may immediately terminate these Terms or the Services if the other party becomes insolvent, 
              admits in writing its inability to pay its debts as they mature, makes an assignment for the benefit of creditors, becomes subject to control 
              of a trustee, receiver or similar authority, or becomes subject to an Insolvency Proceeding.  The Subscription Services is a service, not a good, 
              provided pro rata over the Subscription Period on a daily basis. Your use of the Services after an Insolvency Proceeding commencement is an actual, 
              necessary cost and expense of preserving Your estate. Nothing herein limits SpotOn’s rights of offset or recoupment. SpotOn is entitled to offset 
              or recoup the value of Services provided after You become subject to an Insolvency Proceeding against any Claim brought by or on behalf of You, 
              including state or federal preference, fraudulent transfer, or other avoidance action.
              </p>
              <Title level={3}>18.  LIMITED WARRANTIES AND DISCLAIMERS</Title>
              <p>
              <b>Mutual Warranties.</b>  Each party represents and warrants that: (a) these Terms has been duly authorized, executed, and delivered, and 
              constitutes a valid and binding agreement enforceable against such party in accordance with its terms; (b) no authorization or approval from 
              any third party is required in connection with such party’s execution, delivery, or performance of these Terms; (c) the execution, delivery, 
              and performance of these Terms does not violate any other agreement to which it is a party or by which it is otherwise bound; and (d) it has 
              the right to disclose its Confidential Information.
              </p>
              <p>
              <b>Your Warranties.</b>  You represent and warrant that: (a) You are not on the U.S.’ Specially Designated Persons (“SDN”) list and are not 
              located in or a national resident of any country on the U.S.’ Sanctions Programs and country list; and (b) You will not conduct transactions 
              with countries, persons or entities on OFAC’s SDN lists in violation of applicable U.S. laws.
              </p>
              <p>
              <b>SpotOn Warranty.</b>  SpotOn warrants that it owns or has obtained all necessary rights from its Third-Party Providers to the Services.  
              The Services are not warranted to be totally error-free.
              </p>
              <p>
              <b>Limited Professional Services Warranty.</b>  If You enter into a paid transaction for Professional Services with SpotOn as evidenced by a 
              Proof of Entitlement, SpotOn warrants that the Professional Services shall be performed in a professional and workmanlike manner with 
              reasonable care, knowledge, experience, qualifications, resources, and skills. In the event You notify SpotOn of non-conforming Professional 
              Services within seven (7) days of receiving the non-conforming Professional Services, and such non-conforming Professional Services do not 
              result from Your fault or delay, SpotOn, at its discretion, as Your sole remedy, shall either (a) re-perform the non-conforming Professional 
              Services at no additional charge, or (b) terminate the Professional Services, and refund the prepaid Professional Services not rendered.
              </p>
              <p>
              <b>Disclaimer of Warranties.</b>  EXCEPT FOR EXPRESS WARRANTIES PROVIDED IN THIS SECTION 18 (LIMITED WARRANTIES AND DISCLAIMERS), SPOTON 
              MAKES NO (AND SPOTON SPECIFICALLY DISCLAIMS ALL) REPRESENTATIONS, WARRANTIES OR CONDITIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY 
              OR OTHERWISE, INCLUDING, WITHOUT LIMITATION, REPRESENTATIONS, WARRANTIES OR CONDITIONS: (A) ARISING OUT OF ANY COURSE OF PERFORMANCE, COURSE 
              OF DEALING OR USAGE OF TRADE; (B) OF: (I) SATISFACTORY QUALITY; (II) FITNESS FOR A PARTICULAR PURPOSE; (III) NON-INFRINGEMENT; OR 
              (IV) INTEROPERABILITY WITH THIRD-PARTY PRODUCTS OR SERVICES; AND (C) THAT THE SERVICES WILL BE UNINTERRUPTED, ERROR-FREE OR FREE OF HARMFUL 
              COMPONENTS. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSIONS OF IMPLIED WARRANTIES. IN SUCH AN EVENT, THE ABOVE EXCLUSIONS WILL NOT APPLY 
              SOLELY TO THE EXTENT PROHIBITED BY LAW.
              </p>
              <p>
              <b>No Legal, Financial, Regulatory, Benefits, Accounting, Tax, or Professional Advice.</b>  SpotOn designs the Services, including the 
              functions and processes applicable to SpotOn’s performance of the Services, to assist You in complying with Applicable Laws applicable 
              to the Services.  SpotOn is responsible for the designs.  You, not SpotOn or its Third-Party Providers, are responsible for (a) how You 
              use the Services to comply with Applicable Laws, and (b) the consequences of instructions You give to SpotOn, including instructions 
              relating to the implementation of the Services, provided that SpotOn follows such instructions.  You agree (1) that SpotOn and Third-Party 
              Providers are not acting in a fiduciary capacity in providing You the Services, (2) the Services (A) do not guarantee compliance with 
              Applicable Laws, and (B) may not include functionality necessary to meet Your specific legal or other needs.  Information or materials 
              made available through the Services or on SpotOn’s <a href="https://support.dolceclock.com/help">Knowledge Base</a> are provided
              for informational purposes only.  SpotOn and its Third-Party Providers do not provide You with, and Services do not include, legal, 
              financial, regulatory, benefits, accounting, tax, or professional advice.
              </p>
              <p>
              This Section 18 (LIMITED WARRANTIES AND DISCLAIMERS) survives expiration or termination of the Terms or the Services.
              </p>
              <p>
              <Title level={3}>19.  INDEMNIFICATION</Title>
              </p>
              <p>
              <b>Your Indemnification.</b>  You will defend, indemnify, and hold harmless SpotOn, its Affiliates, and their respective officers, 
              Representatives, directors, successors and assigns (“SpotOn Indemnified Parties”), from and against any Claims and Losses arising 
              out of or relating to: (a) Your, Your Affiliates’, Your Representatives’, or Outsourced Providers’ breach of these Terms; (b) Your, 
              Your Affiliates’, Your Representatives’, or Outsourced Providers’ misappropriation or infringement of any Intellectual Property 
              rights (to the extent such infringement or misappropriation is not the result of SpotOn’s actions); (c) Your, Your Affiliates’, 
              Your Representatives’, or Outsourced Providers’ use of the Services in violation of Services use restrictions (including Section 7 
              (Acceptable Use) and Section 24 (Specific Services Terms)); (d) Your Content, Your Personal Data, Customer Data, and Customer 
              Process Data; or (e) the acts and omissions of You, Your Affiliates’, Your Representatives’, and Outsourced Providers.
              </p>
              <p>
              <b>SpotOn Indemnification.</b> If You enter into a paid transaction with SpotOn as evidenced by a Proof of Entitlement, SpotOn 
              will defend You, Your Affiliates, and their officers, Representatives, directors, successors and assigns (“You Indemnified Parties”), 
              against any third party Claim brought against You Indemnified Parties alleging the Services infringe or misappropriate a United States 
              registered patent, registered trademark, copyright, or trade secret, and indemnify You Indemnified Parties from Losses finally awarded 
              against You Indemnified Parties by a court of competent jurisdiction as a result of, or for amounts paid by You Indemnified Parties under a Claim 
              settlement approved by SpotOn in writing. If SpotOn receives information about an infringement or misappropriation related to the Services, 
              SpotOn may in its discretion and at no cost to You (a) modify the Services so they are no longer claimed to infringe or misappropriate, 
              (b) obtain a license for You to continue using the Services in accordance with these Terms, or (c) terminate Your rights to the Services 
              upon thirty (30) days’ written notice and refund You prepaid Fees for Services not rendered after the effective termination date. SpotOn 
              shall have no indemnification obligations with respect to (1) Claims if You Indemnified Parties are in material breach of this Agreement; 
              (2) modification or alteration of the Services by anyone other than SpotOn or without SpotOn’s written approval,  or (3) Claims arising 
              out of use of the Services, or any part thereof, (A) in combination with software, technology, processes, equipment, services, or other 
              products not supplied by SpotOn, or explicitly supported in the Documentation,  if such Claims would have been avoided without such combination, 
              or (B) not in accordance with these Terms.
              </p>
              <p>
              <b>Exclusive Remedy.</b> Section 19 (Indemnification; SpotOn Indemnification) states SpotOn’s sole and exclusive remedy against, and SpotOn’s 
              sole liability to, You for Claims under Section 19 (Indemnification; SpotOn Indemnification).
              </p>
              <p>
              <b>Indemnification Process.</b>  Indemnification obligations shall be subject to the party seeking indemnification (“Indemnified Party”) 
              (a) notifying the other party (“Indemnifying Party”) in writing within ten (10) days of receiving information of any threatened or actual Claim; 
              provided, however, the failure to give notice by the Indemnified Party shall not relieve the Indemnifying Party’s obligations except to the 
              extent that the Indemnifying Party is prejudiced by such failure; (b) giving the Indemnifying Party exclusive control and authority over the 
              defense or settlement of such Claim, except the Indemnifying Party shall not settle a Claim without the Indemnified Party’s consent when the 
              settlement does not release Indemnified Party of all liability, or requires the Indemnified Party to make admissions, perform actions, 
              or pay moneys or other legal value; (c) not entering into any settlement or compromise of any Claim without the Indemnifying Party’s prior 
              written consent; and (d) providing reasonable assistance requested by the Indemnifying Party at Indemnifying Party’s expense.
              </p>
              <p>
              This Section 19 (Indemnification) survives expiration or termination of the Terms or the Services.
              </p>
              <Title level={3}>20.  LIMITATION OF LIABILITY</Title>
              <p>
              EXCEPT (a) DATA PROTECTION CLAIMS, (b) SECTION 19 (INDEMNIFICATION) OBLIGATIONS, (c) LIABILITY WHICH, BY APPLICABLE LAWS, CANNOT BE LIMITED 
              (E.G., SERIOUS BODILY INJURY OR DEATH CLAIMS ARISING FROM GROSS NEGLIGENCE OR INTENTIONAL MISCONDUCT), (d) YOUR PAYMENT OBLIGATIONS (SECTION 15), 
              (e) YOUR BREACH OF SECTION 7 (ACCEPTABLE USE), SECTION 9 (SPOTON PROPRIETARY RIGHTS), SECTION 12 (CONFIDENTIAL INFORMATION), OR SECTION 24 
              (SPECIFIC SERVICES TERMS), AND (f) YOUR VIOLATION OF SPOTON INTELLECTUAL PROPERTY RIGHTS (“EXCLUDED CLAIMS”), TO THE EXTENT NOT PROHIBITED BY LAW, 
              IN NO EVENT WILL EACH PARTY’S AND ITS AFFILIATES’ TOTAL AND CUMULATIVE LIABILITY, FOR ALL CLAIMS OF ANY NATURE ARISING OUT OF OR RELATED TO THESE 
              TERMS EXCEED THE TOTAL FEES PAID BY YOU TO SPOTON FOR THE SPECIFIC SERVICES UPON WHICH THE FIRST EVENT GIVING RISE TO LIABILITY WAS BASED 
              (“LIABILITY EVENT”) DURING THE TWELVE (12) MONTHS PRECEDING THE LIABILITY EVENT DATE (“GENERAL LIABILITY CAP”). 
              </p>
              <p>
              IN NO EVENT WILL SPOTON BE LIABLE FOR YOUR COSTS TO PROCURE SUBSTITUTE GOODS OR SERVICES.
              </p>
              <p>
              THE LIMITATIONS AND EXCLUSIONS APPLY IF THIS REMEDY DOES NOT FULLY COMPENSATE A PARTY FOR ANY LOSSES OR FAILS OF ITS ESSENTIAL PURPOSE.
              </p>
              <p>
              EXCEPT FOR EXCLUDED CLAIMS, IN NO EVENT WILL A PARTY BE LIABLE FOR INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, COVER, LOSS OF PROFITS OR REVENUE, 
              OR CONSEQUENTIAL DAMAGES (INCLUDING, WITHOUT LIMITATION, LOSS OF GOODWILL, LOSS OR USE OF DATA (INCLUDING CUSTOMER DATA AND CUSTOMER PROCESS DATA)) 
              HOWEVER CAUSED, WHETHER BASED IN CONTRACT, TORT, WARRANTY, NEGLIGENCE, OR OTHER LIABILITY THEORY, EVEN IF A PARTY HAS BEEN ADVISED AS TO THE 
              POSSIBILITY OF SUCH DAMAGES.  SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSIONS OF INCIDENTAL, CONSEQUENTIAL OR OTHER DAMAGES.  IN SUCH AN EVENT, 
              THE ABOVE EXCLUSIONS WILL NOT APPLY SOLELY TO THE EXTENT PROHIBITED BY LAW.
              </p>
              <p>
              IN THE CASE OF DATA PROTECTION CLAIMS, SPOTON’S AND SPOTON AFFILIATES’ TOTAL LIABILITY TO YOU AND YOUR AFFILIATES FOR ALL CLAIMS IN THE 
              AGGREGATE (FOR LOSSES OR LIABILITY OF ANY TYPE) SHALL NOT EXCEED TWO TIMES (2X) THE GENERAL LIABILITY CAP (“DATA PROTECTION CLAIMS CAP”).
              </p>
              <p>
              This Section 20 (LIMITATION OF LIABILITY) survives expiration or termination of the Terms or the Services.
              </p>
              <Title level={3}>21. GOVERNING LAW; VENUE; EQUITABLE RELIEF</Title>
              <p>
              <b>Governing Law.</b>  These Terms will be construed and enforced in all respects in accordance with the laws of the State of California, 
              U.S.A., without reference to its choice of law rules, and without regard to the United Nations Convention on the International Sale of Goods.
              </p>
              <p>
              <b>Venue.</b>  In the event of a Claim arising out of the Terms, the parties submit to exclusive venue in, and the exclusive jurisdiction of, 
              federal and state courts, as applicable, located in San Francisco County, California, U.S.A., subject to Section 21 (Governing Law; Venue; 
              Equitable Relief) on Equitable Relief.
              </p>
              <p>
              <b>Equitable Relief.</b>  Notwithstanding Section 21 (Governing Law; Venue; Equitable Relief) on Venue, either party may enforce a judgment, 
              or seek equitable relief without having to prove actual damages or posting bond, from any court of competent jurisdiction.
              </p>
              <p>
              English is the language governing these Terms.
              </p>
              <p>
              This Section 21 (Governing Law; Venue; Equitable Relief) survives expiration or termination of the Terms or the Services.
              </p>
              <Title level={3}>22.  GOVERNMENT USERS</Title>
              <p>
              If You are a U.S. government entity or these Terms becomes subject to the Federal Acquisition Regulations, You acknowledge that elements of 
              the Services constitute software and documentation and are provided as “Commercial Items” as defined at 48 C.F.R. 2.101, and licensed to the 
              U.S. government as commercial computer software subject to the restricted rights described in 48 C.F.R. 2.101 and 12.212 (or successor sections).
              </p>
              <p>
              This Section 22 (Government Users) survives expiration or termination of the Terms or the Services.
              </p>
              <Title level={3}>23.  MISCELLANEOUS</Title>
              <p>
              <b>Publicity.</b>  You hereby consent to SpotOn using Your Identifiers in any customer or vendor lists, advertisements, websites, news or 
              press releases, releases to professional or trade publications, or in any document that SpotOn plans to file with the Securities and Exchange 
              Commission or other government authority.
              </p>
              <p>
              <b>Severability.</b>  In the event a provision of the Terms becomes or is declared by a court of competent jurisdiction to be illegal, void, 
              or unenforceable, the remainder of the Terms continue in full force and effect and the application of such provision will be interpreted so 
              as reasonably to effect the parties’ intent.
              </p>
              <p>
              <b>Force Majeure.</b>  In the event that either party is prevented from performing, or unable to perform obligations under the Terms due to 
              a Force Majeure Event (except for Your obligations under Sections 15 (Fees; Taxes) and 16 (Billing/Payment)), the affected party’s performance 
              will be excused and the time for performance extended for the period of delay or inability to perform due to such occurrence; provided that 
              the affected party: (a) provides the other party prompt notice (to the extent possible) of the nature and expected duration of the Force 
              Majeure Event; (b) uses commercially reasonable efforts to address and mitigate the cause and effect of such Force Majeure 
              Event; (c) provides periodic notice of relevant developments; and (d) provides prompt notice of the end of such Force Majeure Event. 
              You understand that the Services may not be provided in countries listed on the Office of Foreign Assets Control sanction list and that 
              Your rights to Services may be restricted in such countries and such prohibitions shall not constitute a Force Majeure Event.
              </p>
              <p>
              <b>Integration.</b>  These Terms constitute the entire agreement between the parties and supersedes all prior agreements or communications 
              between the parties with regard to the subject matter.  Subject to Section 2 (Changes to These Terms), these Terms may not be amended or 
              modified except by a writing signed by each party.  The Terms supersedes and controls over conflicting or additional terms and conditions 
              of any purchase order, acknowledgement, confirmation, or other document You issue.  In the event of conflict, the following is the order 
              of precedence:  (a) Quote or SOW for the specific Quote or SOW only, (b) Terms, (c) SpotOn Terms and Policies, and (d) SpotOn 
              Teamwork Privacy Policy.
              </p>
              <p>
              <b>Assignment.</b>  You will not, directly, indirectly, by operation of law or otherwise, assign or transfer all or part of these Terms 
              or its rights or delegate performance of its duties without the prior written consent of SpotOn. Any attempted assignment or transfer 
              by You without consent shall be void and of no effect. Either party may assign the Terms upon written notice, but without obtaining 
              the other party’s consent: (a) to an Affiliate; or (b) in connection with a successor in interest in a merger, acquisition, reorganization, 
              sale of all or substantially all of the assets, or other change of Control, provided however, if You assign the Terms to an assignee that 
              SpotOn does not want to do business with, SpotOn may terminate the Terms and related Quotes and SOWs immediately with written notice. 
              Subject to the foregoing, the Terms will be fully binding upon, inure to the benefit of, and be enforceable by, the parties and their 
              respective permitted successors and assigns.
              </p>
              <p>
              <b>Third Party Beneficiaries.</b>  Nothing in these Terms shall confer, or is intended to confer, on any third party any benefit or the 
              right to enforce these Terms.
              </p>
              <p>
              <b>Relationship.</b>  The parties enter into the Terms as independent contracting parties.  Neither party will have, or hold itself out as 
              having, any right or authority to incur any obligation on behalf of the other party.  These Terms will not be construed to create an 
              association, joint venture, or partnership between the parties or to impose any partnership liability upon any party.
              </p>
              <p>
              <b>Nonwaiver.</b>  The failure of either party to insist upon or enforce strict performance of any Terms provision or to exercise rights 
              or remedies under the Terms will not be construed as a waiver or relinquishment to any extent of such party’s right to assert or rely 
              upon any such provision, right, or remedy in that or any other instance; rather, the same will remain in full force and effect.
              </p>
              <p>
              <b>Compliance with Laws.</b> SpotOn and You agree to comply with Applicable Laws, including but not limited to those relating to 
              anti-corruption, anti-bribery (e.g. U.S. Foreign Corrupt Practices Act, as amended), and exports (including restrictions on destinations, 
              end users, and end use, including without limitations, those of the U.S. Treasury Department’s Office of Foreign Assets Control (“OFAC”) 
              and/or Department of Commerce’s Bureau of Industry and Security).
              </p>
              <p>
              <b>Notices.</b>  Notice given by a party to any other party will be in writing and effective upon confirmed delivery as follows: (a) if 
              to You, when sent via email or physical address specified in a Quote or otherwise on record for You; and (b) if to SpotOn, when sent via 
              email to Legal@spoton.com or SpotOn Transact LLC, 100 California Street, 9th Floor, San Francisco, CA  94111, Attn:  SpotOn Terms of 
              Services Notices.   A notice must specifically reference that it is a notice given under these Terms.  Emailed notices will 
              be considered given and received when the email is sent.  You agree to accept service of process by mail.
              </p>
              <p>
              This Section 23 (Miscellaneous) survives expiration or termination of the Terms or the Services.
              </p>
              <Title level={3}>24.  SPECIFIC SERVICES TERMS</Title>
              <p>
              You agree to the following additional terms for the specific Services You purchase, use, or access:
              </p>
              <p>
              <b>Gusto, Inc. Embedded Payroll Services.</b>
              </p>
              <p>
              <u>Gusto, Inc. (“Gusto”) Embedded Payroll Service Agreement.</u> You agree to Gusto, Inc. Embedded Payroll Service Agreement when using 
              Payroll as part of SpotOn Teamwork: <a href="https://flows.gusto.com/terms">https://flows.gusto.com/terms</a>
              </p>
              <p>
              <u>Compliance with Applicable Laws Relating to Services.</u>  You agree, that between You, SpotOn, and Third-Party Providers, You are 
              solely responsible for complying with Applicable Laws relating to the Services, including federal, state, and local employment and tax 
              laws, including but not limited to wage and hour, employee classification, minimum wage, wage deduction, meal period, rest break, 
              regular rate of pay calculations, overtime calculations and payments, paid sick leave accrual, itemization and issuance of pay statements, 
              and timing of pay (including final pay).
              </p>
              <p>
              <u>Online Pay Statements.</u>  If You instruct SpotOn to provide online pay statements (other similar local forms) without physical copies 
              thereof, You will be exclusively responsible for determining if and to what extent Your use of online pay statements (or other similar 
              local forms) satisfies Your obligations under Applicable Laws and the consequences resulting from such determinations.
              </p>
              <p>
              <u>Acceptable Use.</u>  Except as otherwise expressly authorized herein, You shall not (and shall use commercially reasonable efforts to 
              detect and prevent any of the following from being undertaken by Your Representatives, Affiliates, Outsourced Providers): (a) Services 
              available on a “white label,” “gray label,” or “private label” basis; (b) access or use Services in a manner that could damage, disable, 
              overburden, or impair its functionality.
              </p>
              <p>
              <u>Your Indemnification.</u>  You will be solely responsible for and shall indemnify, defend, and hold harmless SpotOn Indemnified Parties 
              from and against all Losses to the extent arising out of or relating to (a) payroll submission by You without sufficient funds at the time 
              of debit, or payroll submission by You where funds are debited successfully but later reversed because of a dispute (such Losses, “Credit Losses”), 
              or (b) fraudulent transactions or criminal activity undertaken through or in connection with Your accounts (such Losses, “Fraud Losses,” and 
              Credit Losses and Fraud Losses, collectively, “Credit/Fraud Losses”). Without limiting the foregoing, to the extent SpotOn or a Third Party 
              Provider is unable to collect Credit/Fraud Losses within 90 days after the act or omission that resulted in, enabled, or otherwise led to such 
              Credit/Fraud Losses, SpotOn will invoice You for the amount of such Credit/Fraud Losses and You will promptly (and no later than 15 days after 
              receipt of such invoice) reimburse SpotOn for such Credit/Fraud Losses.
              </p>
              <p>
              <u>Security and Privacy.</u>  From time to time, SpotOn may notify You of certain guidelines and recommendations for security and privacy 
              practices (for example, regarding the use of two-factor authentication) with respect to the Services, whether applicable to You, or Your 
              Affiliates, Representatives, or Outsourced Providers, including proposed timeframe(s) for implementing such guidelines and recommendations 
              (“Security Guidelines,” and each date on which SpotOn notifies You of such Security Guidelines, a “Trigger Date”). For purposes of this Agreement, 
              Security Guidelines that if not implemented, could reasonably be expected to lead to legal liability for or a material negative impact to SpotOn 
              (including with respect to data privacy and data security), will be deemed “Urgent Security Guidelines.”  If You are unable to implement certain 
              Security Guidelines, then You shall notify SpotOn thereof within fifteen (15) days (or, for Urgent Security Guidelines, one (1) business days) 
              after the applicable Trigger Date (a “Decline Notice,” and such period, the “Decline Period”), in which case, SpotOn may terminate this Agreement 
              by providing notice of termination to You within five (5) business days of the Decline Notice, with such termination effective ten (10) 
              business days from notice of such termination. If You agree to implement the Security Guidelines or do not send a Decline Notice within the 
              Decline Period, then You shall implement and, to the extent applicable, require Your Affiliates, Representatives, and 
              Outsourced Providers to implement, the applicable Security Guidelines on or before the implementation date(s) proposed by SpotOn.
              </p>
              <p>
              Section 24 (Specific Services Terms) shall control in the event of conflict for the specific Services.
              </p>
            </div>
          </Col>
          <Col lg={8} sm={24}>
            <div className="aside">
              <Title level={3}>Related Documents</Title>
              <CtaSecondary
                target="/legal/consumer-privacy"
                ctaTitle="Consumer Privacy"
              />
              <CtaSecondary
                target="/legal/merchant-privacy"
                ctaTitle="Merchant Privacy"
              />
              <CtaSecondary
                target="/legal/merchant-terms"
                ctaTitle="Merchant Terms"
              />
              <CtaSecondary
                target="/payments-terms.pdf"
                ctaTitle="Payments Terms"
              />
              <CtaSecondary 
                target="/legal/user-terms" 
                ctaTitle="User Terms" 
              />
              <CtaSecondary
                target="/legal/point-of-sale-terms"
                ctaTitle="Point-of-Sale Terms"
              />
              <CtaSecondary
                target="/legal/website-terms"
                ctaTitle="Website Terms"
              />
               <CtaSecondary
                target="/legal/reserve-terms"
                ctaTitle=" Reserve Terms"
              />
            </div>
          </Col>
        </Row>
      </main>

      <LegalStyles />
    </Layout>
  );
};

export default ReserveTerms;
